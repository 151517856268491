@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;200;400;500;700;900&family=Cormorant+Garamond:wght@300');

.overlay {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgba(0, 0, 0, 0.45);
    z-index:9999;
    color:white;
}


.loading {
  padding-top: 10vh;
}

/* NAVIGATION CSS */
body {
  margin: 0;
  font-family: Montserrat,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --top-bottom-margin: 15px;
  --navigation-width: 80px;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.xClose {
  font-size: 46px;
}

.bar {
  width: 40px;
  height: 5px;
  margin-top: 8px;
  background-color: black;
}

.sidepanel a {
  padding: 32px;
  border-bottom: 0.5px solid;
  text-decoration: none;
  font-size: 18px;
  color: black;
  display: flex;
  transition: 0.3s;
}

.data-tip {
  font-weight: 400px;
}

.sidepanel a:hover {
  color: #f1f1f1;
  background-color: black;
}

.middlePanel {
  justify-content: center;
}

.leftPanel {
  width: 100%;
}

.rightPanel {
  text-align: left;
  width: 100%;
}

.header {
  font-size: 18px;
  font-weight: 900;
}

.sub {
  font-weight: 300;
}

.dash {
  font-size: 22px;
  font-weight: 900;
}

.arrowRight, .arrowLeft {
  display:flex;
  align-items: center;
  z-index: 10;
  padding: 20px;
  cursor: pointer;
}

.arrowRight:hover, .arrowLeft:hover {
  background-color: #fcfcfc;
}

.rightPanel p {
  margin-bottom: -12px;
}

.rightPanel a {
  text-decoration: none;
  color: black;
}

.rightPanel a:hover {
  text-decoration: none;
  color: #adadad;
}

.browseImage {
  height: calc(100vh - var(--top-bottom-margin) * 2);
  margin: var(--top-bottom-margin);
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.browseView, .browseImage {
  min-height: 700px;
}

/* ABOUT VIEW */
.middlePanelAbout {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  padding-top: 60px;
}

.miniDivider {
  font-size:  24px;
  font-weight: 900;
}

.aboutHeader {
  font-size: 48px;
  font-weight: 900;
  padding-bottom: 16px;
}

.aboutSubHeader {
  font-size: 30px;
  font-weight: 900;
  padding-top: 20px;
}

.aboutParagraph {
  font-family: Cormorant Garamond;
  font-size: 24px;
  line-height: 1.5;
}

.aboutParagraph a {
  text-decoration: none;
  color: #898989;
}

.aboutParagraph a:hover {
  color: black;
}

.smallSpace {
  height: 5vh;
}

.aboutWrapper {
  overflow: scroll;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height:  100%;
}

.space {
  height: 15vh;
}



.navigation {
  background-color: white;
}

.title {
  font-size: 32px;
}

.burgerContainer {
  cursor: pointer;
}

.browseView {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height: 100vh;
  display: flex;
  justify-content: space-between;
}

.sidepanel {
  top: 0;
  background-color: white; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.8s;
  border-right: 0.5px solid;
  border-color: #000;
}

/* Mobile view */

@media (max-width: 767px) {
  .App {
    width: 100vw;
  }

  .middlePanelAbout {
    text-align: left;
    margin-left: 5%;
    margin-right: 5%;
  }

  .navigation {
    width: 100vw;
    height: var(--navigation-width);
    border-bottom: 0.5px solid;
    display: flex;
    position: fixed;
    z-index: 13;
  }




  .title {
    padding-left: 20px;
    display: inline;
  }

  .burgerContainer {
    display: inline;
    padding: var(--top-bottom-margin);
    width: 30px;
  }

  .browseView {
    flex-direction: column;
  }

  html, body {
    overflow-x: hidden;
  }
  body {
    position: relative
  }

  .sub {
    text-decoration: underline dotted;
    color: #898989;
  }


  .browseViewMobile {
    display: flex;
    flex-direction: column;
  }

  .middlePanelMobile {
    margin-top: calc(var(--navigation-width) * 1.05);
  }

  .browseImageMobile {
    width: calc(100vw - (2*var(--top-bottom-margin)));
    margin-top: var(--top-bottom-margin);
    margin-bottom: 0px;
    margin-left: var(--top-bottom-margin);
    margin-right: var(--top-bottom-margin);
  }

  .ImageInfoMobile {
    width: 100vw;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: var(--top-bottom-margin);
    text-align: center;
  }

  .sidepanel {
    position: fixed;
    width: 100%;
    z-index: 9;
  }
}

@media (min-width: 768px) {
  body {
    overflow: hidden;
  }

  .middlePanelAbout {
    width: 50vw;
    min-width: 50vw;
    text-align: justify;
    text-justify: distribute;
  }

  .aboutWrapper {
    display: flex;
  }


  .browseView {
    width: 96%;
  }

  .navigation {
    width: var(--navigation-width);
    height: 100vh;
    z-index: 11;
    border-right: 0.5px solid;
  }

  .title {
    position: absolute;
    transform: translate(60px, 15px) rotate(90deg);
    transform-origin: 0 0;
  }

  .burgerContainer {
    padding-top: var(--top-bottom-margin);
    padding-left: 20px;
    height: 60px;
    cursor: pointer;
  }

  .browseView {
    flex-direction: row;
  }

  .App {
    display: flex;
  }

  .sidepanel {
    height: 100vh;
    transform: translate(-400px);
    position: fixed;
    width: 400px;
    z-index: 10; /* Stay on top */
  }
}


